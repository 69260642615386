// extracted by mini-css-extract-plugin
export var title = "post-module--title--ohnYl";
export var yeah = "post-module--yeah--WPZeV";
export var vamosTrabalhar = "post-module--vamosTrabalhar--7S6TU";
export var tags = "post-module--tags--cRnNv";
export var location = "post-module--location--qwvKR";
export var description = "post-module--description--cCLfi";
export var about = "post-module--about--Dy3v7";
export var anterior = "post-module--anterior--sYzNn";
export var proximo = "post-module--proximo--XU7q6";
export var devolta = "post-module--devolta--mlz7f";
export var aoinicio = "post-module--aoinicio--UbghI";