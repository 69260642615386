import React, { useRef, useState } from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types';
import Layout from '../components/layout'
import Cursor from '../components/cursor'
import * as styles from './post.module.css'
import ImageHero from '../components/design-grafico/image-post';
import ImageRelated from '../components/design-grafico/image-hero';
import ImageGallery from '../components/design-grafico/image-gallery';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import yeah from "../images/yeah.svg"


const DesignGraficoPostTemplate = ({ data, pageContext }) => {

	const containerRef = useRef(null);

	const [modalVisible, setIsShowing] = useState(false);

	function updateStateModal() {
		setIsShowing(!modalVisible);
	}

	const post = data.contentfulDesignGrafico;


	let description;
	if (post.description) {
		description = post.description.description
	}

	let about;
	if (post.about) {
		about = post.about.about
	}

	let prevSlug;
	if (post.prev) {
		prevSlug = post.prev.slug
	}

	let prevFolder;
	if (post.prev) {
		prevFolder = post.prev.folder
	}

	let nextSlug;
	if (post.next) {
		nextSlug = post.next.slug
	}

	let nextFolder;
	if (post.next) {
		nextFolder = post.next.folder
	}



	return (
		<>
			<LocomotiveScrollProvider options={{ smooth: true, tablet: { smooth: true }, smartphone: { smooth: false } }} watch={[]} containerRef={containerRef}>
				<main id="top" data-scroll-container ref={containerRef}>
					<Layout type="post" left="designer gráfico" showreelModal={updateStateModal} >

						<section id="content" className="post-content p-relative">

							<div className="post-top" id="post-top">
								<div className="max-width">
									<div className="row">
										<div className="col-xs-12 pt-100" data-scroll data-scroll-sticky data-scroll-target="#post-top">

											<h1 className={`${styles.title} animated`} data-scroll>
												{post.title}
											</h1>

											<h3 className={`${styles.tags} animated`} data-scroll>
												{post.tags}
											</h3>

										</div>
									</div>
								</div>

								<div className="max-width p-relative">
									<div className="row">
										<div className="col-xs-12">

											<ImageHero foldername={post.folder} alt={post.title} />

										</div>
									</div>
								</div>
							</div>

							<div className="max-width">
								<div className="row">
									<div className="col-xs-12">

										<p className={`${styles.location} animated`} data-scroll>
											{post.location}
										</p>

										<p className={`${styles.description} animated`} data-scroll>
											{description}
										</p>

										<div className={`${styles.about} animated p-relative`} data-scroll>
											<div className='line-orange'></div>
											<p>{about}</p>
										</div>

										<ImageGallery alt={post.title} foldername={post.folder} />

									</div>
								</div>
							</div>
						</section>

						<section id="next-projects" className='p-relative next-projects'>

							{/* <div className="gray-gradient" data-scroll data-scroll-speed={3} /> */}

							<div className="p-relative max-width col next-projects">
								<div className="row pb-100">

									<div className="col-xs-12">
										<div className='justify-center'>
											<img className="post-yeah" src={yeah} alt="yeah" />
										</div>
									</div>

									{/* <div className="col-xs-12">
										<p className={`${styles.yeah} animated`} data-scroll>
											yeah!
										</p>
									</div>

									<div className="col-xs-12">
										<div className='justify-center'>
											<Link className={`${styles.vamosTrabalhar} animated`} data-scroll to='/contactos'>vamos trabalhar?</Link>
										</div>
									</div> */}

								</div>

								{post.prev &&

									<div className="row between-xs pb-150">

										<Link to={`/portfolio/${prevSlug}/`} className="col-xs-5 col-sm-4 portfolio-item next-projects-item animated" data-scroll>
											<div className="p-relative portfolio-item--hover align-center">
												<span className={`${styles.anterior} `}> anterior</span>
												<ImageRelated foldername={prevFolder} />
											</div>
										</Link>

										<div className="col-sm-4 col-xs-12 justify-center align-center p-relative animated devolta" data-scroll>
											<a href="#top" className='d-flex back' data-scroll-to>
												<span className={`${styles.devolta}`} data-scroll-to>de volta</span>
												<span className={`${styles.aoinicio}`} data-scroll-to>ao início</span>
												<div className='line-orange'></div>
											</a>

										</div>

										<Link to={`/portfolio/${nextSlug}/`} className="col-xs-5 col-sm-4 portfolio-item next-projects-item animated" data-scroll>
											<div className="p-relative portfolio-item--hover align-center">
												<span className={`${styles.proximo} `}> próximo</span>
												<ImageRelated foldername={nextFolder} />
											</div>
										</Link>

									</div>
								}

							</div>

						</section>

						{/* <AllPosts slug={pageContext.slug} next={nextSlug} prev={prevSlug}></AllPosts> */}

					</Layout>
				</main>
			</LocomotiveScrollProvider>
			<Cursor />
		</>
	)
}

DesignGraficoPostTemplate.propTypes = {
	data: PropTypes.object.isRequired,
};

export default DesignGraficoPostTemplate

export const pageQuery = graphql`
  query contentfulDesignGrafico($slug: String!) {
									contentfulDesignGrafico(slug: {eq: $slug }) {
									title
									slug
									tags
									location
									description {
										description
									}
									about {
										about
									}
									folder
									order
									next {
										title
										folder
										slug
									}
									prev {
										title
										folder
										slug
									}
    }
  }
	
									`
